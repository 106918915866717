import React, { useState } from "react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getVideoDetails } from "./../../../store/video.details.reducers";
import { getDate, getDateByTimeZoneCba } from "./../../../services/functions";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import MainServices from "./../../../components/MainServices";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import Share from "./../../../components/Share";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ContentFigure,
  Iframe,
  ContentBox,
  ContentTitle,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
} from "./styles";

const frontload = async props => {
  await props.getVideoDetails(`${process.env.REACT_APP_API}/videos/uri/${props.match.params.uri || ""}`, {}, props.history);
  return;
};

function Details() {
  let { videoDetailsReducers: details } = useSelector(state => state);
  details = details.videoDetails;

  const [fontSize, setFontSize] = useState(0);

  function getIdYoutube(url) {
    if (url) {
      const part1 = url.split("?v=")[1];
      const part2 = part1.split("&")[0];

      return part2;
    }
  }

  return (
    <>
      <Helmet>
        <title>{details.title}</title>
        <meta name="description" content={details.title} />
        <meta property="og:title" content={details.title} />
        <meta name="title" content={details.title} />
        <meta property="og:description" content={details.title} />
        <meta property="og:image" content={details.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`${details.title}`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentFigure>
                  <Iframe
                    src={`https://www.youtube.com/embed/${getIdYoutube(
                      details.video_path,
                    )}?autoplay=0&amp;showinfo=0&amp;modestbranding=0&amp;controls=0&amp;rel=0`}
                    frameborder="0"
                    controls=""
                    rel="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></Iframe>
                </ContentFigure>

                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                    <Share />
                  </ContentZoom>
                  <ContentLine fontSize={fontSize}>{getDateByTimeZoneCba(details.date, "dd 'de' MMMM 'de' yyyy")}</ContentLine>
                  <ContentTitle fontSize={fontSize}>{details.title}</ContentTitle>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    videoDetails: state.videoDetailsReducers.videoDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getVideoDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
