import React, { useState, useEffect } from "react";

import { Link, useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getScheduleDetails } from "./../../../store/schedule.details.reducers";
import { getDate, getDateByTimeZoneCba } from "./../../../services/functions";
import api from "./../../../services/api";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import Calendar from "./../../../components/Calendar";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  CategoriaLists,
  CategoriaList,
  CategoriaItem,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
  EventLastedLink,
  SummaryBox,
} from "./styles";

const frontload = async props => {
  await props.getScheduleDetails(`${process.env.REACT_APP_API}/schedule/uri/${props.match.params.uri || ""}`, {}, props.history);
  return;
};

function Details() {
  let { scheduleDetailsReducers: schedule } = useSelector(state => state);
  schedule = schedule.scheduleDetails;

  const { uri } = useParams();
  const history = useHistory();

  const [filter, setFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [fontSize, setFontSize] = useState(0);

  useEffect(() => {
    async function loadCategories() {
      const { data } = await api.get("/schedule/types", {});
      setCategories(data);
    }

    loadCategories();

    if (!uri) {
      history.push("/agenda");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{schedule.name}</title>
        <meta name="description" content={schedule.summary} />
        <meta property="og:title" content={schedule.name} />
        <meta name="title" content={schedule.name} />
        <meta property="og:description" content={schedule.summary} />
        <meta property="og:image" content={schedule.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`${schedule.name}`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Pesquisar na agenda</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por cursos, eventos, palestras…</FilterLabel>
                    <FilterInput type="text" value={filter} onChange={event => setFilter(event.target.value)} />
                    <FilterButton onClick={() => {}}>Pesquisar</FilterButton>

                    <EventLastedLink to={`/agenda-anteriores`}>Eventos anteriores</EventLastedLink>
                  </BoxContent>
                </Box>

                <Calendar
                  style={{
                    marginBottom: "50px",
                  }}
                />

                <Box>
                  <BoxTitle>Categorias</BoxTitle>
                  <BoxContent>
                    <CategoriaLists>
                      <CategoriaList>
                        {categories.map(category => (
                          <Link to={`/agenda?categoria=${category.uri}`}>
                            <CategoriaItem color={category.color}>{category.name}</CategoriaItem>
                          </Link>
                        ))}
                      </CategoriaList>
                    </CategoriaLists>
                  </BoxContent>
                </Box>
              </Aside>

              <ContentBody>
                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                  </ContentZoom>
                  <SummaryBox>
                    <div style={{ flex: 1 }}>
                      <ContentTitle fontSize={fontSize}>{schedule.name}</ContentTitle>
                      <ContentSummary fontSize={fontSize}>{schedule.summary}</ContentSummary>
                    </div>

                    {schedule.image_path ? (
                      <ContentFigure title={schedule.name} alt={schedule.name}>
                        <ContentImage src={schedule.image_path} title={schedule.name} alt={schedule.name} />
                      </ContentFigure>
                    ) : (
                      <></>
                    )}
                  </SummaryBox>

                  <ContentLine fontSize={fontSize}>
                    <label>Data:</label> {getDateByTimeZoneCba(schedule.start_date, "dd 'de' MMMM 'de' yyyy")}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    <label>Horário:</label> {getDateByTimeZoneCba(schedule.start_date, "HH:mm'hrs'")}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    <label>Local:</label> {`${schedule.address}${schedule.city ? `, ${schedule.city}` : ``}`}
                  </ContentLine>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    scheduleDetails: state.scheduleDetailsReducers.scheduleDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getScheduleDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
