import React from "react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getRegional } from "./../../store/regional.reducers";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import phone2 from "./../../assets/icons/phone2.svg";
import map2 from "./../../assets/icons/map2.svg";
import email2 from "./../../assets/icons/email2.svg";
import clock2 from "./../../assets/icons/clock2.svg";
import whatsapp from "./../../assets/icons/whatsapp.svg";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemLine,
} from "./styles";

const frontload = async props => {
  await props.getRegional(`${process.env.REACT_APP_API}/regionais?is_online=false`, {});
  return;
};

function Regional() {
  let { regionalReducers: regional } = useSelector(state => state);
  regional = regional.regional.data;

  return (
    <>
      <Helmet>
        <title>Conheça nossas delegacias regionais e entre em contato</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Conheça nossas delegacias regionais e entre em contato`} />
        <meta name="title" content={`Conheça nossas delegacias regionais e entre em contato`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Delegacias`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                {regional.map(item => (
                  <ListItem key={item.id}>
                    <ListItemFigure title={item.city} alt={item.city}>
                      <ListItemImage src={item.image_path} title={item.city} alt={item.city} />
                    </ListItemFigure>
                    <ListItemContent>
                      <ListItemTitle>{item.city}</ListItemTitle>
                      <ListItemSubTitle>Delegado(a): {item.delegate}</ListItemSubTitle>

                      <ListItemLine>
                        <img src={map2} alt="endereço" /> {item.address}
                      </ListItemLine>
                      <ListItemLine>
                        <img src={clock2} alt="horário de funcionamento" /> {item.business_hours}
                      </ListItemLine>
                      <ListItemLine>
                        <img src={phone2} alt="Telefones" /> {item.phones}
                      </ListItemLine>
                      <ListItemLine>
                        <img src={email2} alt="E-mails" /> {item.emails}
                      </ListItemLine>

                      {item.whatsapp ? (
                        <ListItemLine style={{ marginTop: 10 }}>
                          <img style={{ width: 20, height: 20 }} src={whatsapp} alt="Whatsapp" />
                          <a target="_blank" href={`https://wa.me/55${item.whatsapp.replace(/\D/g, "")}`}>
                            {item.whatsapp}
                          </a>
                        </ListItemLine>
                      ) : (
                        <></>
                      )}
                    </ListItemContent>
                  </ListItem>
                ))}
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    regional: state.regionalReducers.regional,
  };
};
const mapDispatchToProps = {
  getBanner,
  getRegional,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Regional),
);
