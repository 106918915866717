import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, NavItems, NavItem } from "./styles";

import Dropdown from "./../Dropdown";

import Triangle from "./../../assets/icons/triangle";

export default function Nav({ items }) {
  const [showInDropdown, setShowInDropdown] = useState(0);

  const getNav = (id, name, uri, target, title, has_sub_navigation, sub_navigations) => {
    if (uri !== "#" && uri !== "") {
      if (uri.substr(0, 4) === "http") {
        return (
          <a alt={title} target={target} href={uri}>
            {name} {sub_navigations.length > 0 ? <Triangle fillColor="#861811" width={6} height={5} /> : <></>}
          </a>
        );
      } else {
        return (
          <Link to={uri || `/`} alt={title} target={target}>
            {name} {sub_navigations.length > 0 ? <Triangle fillColor="#861811" width={6} height={5} /> : <></>}
          </Link>
        );
      }
    } else {
      return (
        <a alt={title} target={target} onClick={() => setShowInDropdown(id)}>
          {name} {sub_navigations.length > 0 ? <Triangle fillColor="#861811" width={6} height={5} /> : <></>}
        </a>
      );
    }
  };

  return (
    <Container id="nav">
      <NavItems>
        <NavItem>
          <Link to="/" alt="Acessar a página inicial do CRO-MT">
            Home
          </Link>
        </NavItem>
        {items.map(({ id, name, uri, target, title, has_sub_navigation, sub_navigations }) => (
          <NavItem key={id} onMouseEnter={() => setShowInDropdown(id)} onMouseLeave={() => setShowInDropdown(0)}>
            {getNav(id, name, uri, target, title, has_sub_navigation, sub_navigations)}

            {sub_navigations.length > 0 ? <Dropdown items={sub_navigations} showing={showInDropdown === id ? true : false} /> : <></>}
          </NavItem>
        ))}
      </NavItems>
    </Container>
  );
}
