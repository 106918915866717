import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getRegional } from "./../../store/regional.reducers";

import api from "./../../services/api";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import Speaker from "./../../assets/icons/speaker";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemSummary,
  ListItemLink,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  AsideButton,
  SubTitle,
  Items,
  Item,
  ItemTitle,
  ItemSubTitle,
  ItemOnline,
} from "./styles";

const frontload = async props => {
  await props.getRegional(`${process.env.REACT_APP_API}/regionais?is_online=true`, {});
  return;
};

function RegionalOnline(props) {
  let { regionalReducers: regional } = useSelector(state => state);
  regional = regional.regional.data;

  const history = useHistory();

  // Função para determinar se uma delegacia está online ou offline
  const isOnline = item => {
    const now = moment().tz("America/Cuiaba");
    const currentDay = now.day();
    const currentTime = parseInt(now.hours() + "" + now.minutes());

    const isOpen = item.days.includes(currentDay);

    if (isOpen) {
      return currentTime >= parseInt(item.start_hour.replace(":", "")) && currentTime <= parseInt(item.end_hour.replace(":", ""));
    }

    return false;
  };

  // Separar delegacias em online e offline
  const onlineDelegacias = [];
  const offlineDelegacias = [];

  regional.forEach(item => {
    if (item.id) {
      if (isOnline(item)) {
        onlineDelegacias.push(item);
      } else {
        offlineDelegacias.push(item);
      }
    }
  });

  React.useEffect(() => {
    const hideChat = () => {
      if (typeof window !== "undefined") {
        const chatIframe = document.querySelector("#iframe-sz-chat");
        if (chatIframe) {
          chatIframe.style.opacity = "0";
        }
      }
    };

    hideChat();

    // Adiciona listeners para garantir que o iframe seja ocultado após o carregamento completo
    if (typeof window !== "undefined") {
      window.addEventListener("load", hideChat);
      return () => window.removeEventListener("load", hideChat);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Delegacias Online</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Delegacias Online`} />
        <meta name="title" content={`Delegacias Online`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Acesse abaixo a Delegacia de sua região:`} />
            <ContentMain>
              <ContentBody>
                <SubTitle>Online</SubTitle>
                <Items>
                  {onlineDelegacias.map(item => (
                    <ItemOnline key={item.id} href={`https://www.cromt.org.br/delegacias-online/${item.id}`}>
                      <ItemTitle>{item.city}</ItemTitle>
                      <ItemSubTitle>Delegado(a): {item.delegate}</ItemSubTitle>
                      <ItemSubTitle>
                        Horário de funcionamento: <b>{item.start_hour.replace(":", "h")}</b> - <b>{item.end_hour.replace(":", "h")}</b>
                      </ItemSubTitle>
                      <ItemSubTitle>
                        Telefone: <b>{item.phones}</b>
                      </ItemSubTitle>
                      <ItemSubTitle>
                        E-mail: <b>{item.emails}</b>
                      </ItemSubTitle>
                    </ItemOnline>
                  ))}

                  {onlineDelegacias.length === 0 && <label>Nenhuma delegacia online encontrada</label>}
                </Items>
                <SubTitle>Offline</SubTitle>
                <Items>
                  {offlineDelegacias.map(item => (
                    <Item>
                      <ItemTitle>{item.city}</ItemTitle>
                      <ItemSubTitle>Delegado(a): {item.delegate}</ItemSubTitle>
                      <ItemSubTitle>
                        Horário de funcionamento: <b>{item.start_hour.replace(":", "h")}</b> - <b>{item.end_hour.replace(":", "h")}</b>
                      </ItemSubTitle>
                      <ItemSubTitle>
                        Telefone: <b>{item.phones}</b>
                      </ItemSubTitle>
                      <ItemSubTitle>
                        E-mail: <b>{item.emails}</b>
                      </ItemSubTitle>
                    </Item>
                  ))}
                </Items>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    regional: state.regionalReducers.regional,
  };
};
const mapDispatchToProps = {
  getBanner,
  getRegional,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(RegionalOnline),
);
