import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getSchedule } from "./../../store/schedule.reducers";
import { getDate, handlePaginateChange, handleGetPaginate, stripAccents, getDateByTimeZoneCba } from "./../../services/functions";
import api from "./../../services/api";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Calendar from "./../../components/Calendar";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

import Cromt from "./../../assets/icons/cromt";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  CategoriaLists,
  CategoriaList,
  CategoriaItem,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemDate,
  ListItemSummary,
  ListItemLink,
  BoxType,
  EventLastedLink,
} from "./styles";

let PATH = "/agenda/";
const frontload = async props => {
  await props.getSchedule(`${process.env.REACT_APP_API}/schedule`, {
    params: {
      anteriores: "sim",
    },
    headers: {
      pagenumber: props.match.params.page || 1,
      pagelimit: 10,
      term: stripAccents(props.match.params.filter || ""),
      datee: props.match.params.date || "",
      type: props.match.params.category || "",
    },
  });
  return;
};

function Schedule(props) {
  if (props.match.params.filter) PATH = `/agenda-anteriores/buscar/${props.match.params.filter}/`;
  if (props.match.params.date) PATH = `/agenda-anteriores/data/${props.match.params.date}/`;
  if (props.match.params.category) PATH = `/agenda-anteriores/categoria/${props.match.params.category}/`;

  let { scheduleReducers: events } = useSelector(state => state);
  const paginate = events.schedule;
  events = events.schedule.data;

  const [filter, setFilter] = useState();
  const [categories, setCategories] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function loadCategories() {
      const { data } = await api.get("/schedule/types", {});
      setCategories(data);
    }

    loadCategories();
  }, []);

  function sendSearch() {
    if (filter) {
      history.push(`/agenda-anteriores/buscar/${filter}/`);
    } else {
      history.push(`/agenda-anteriores/`);
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Fique por dentro do que aconteceu - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta
          property="og:title"
          content={`Fique por dentro do que aconteceu - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`}
        />
        <meta name="title" content={`Fique por dentro do que aconteceu - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Histórico de Eventos Anteriores`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Pesquisar na agenda</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por cursos, eventos, palestras…</FilterLabel>
                    <FilterInput type="text" value={filter} onChange={event => setFilter(event.target.value)} />
                    <FilterButton
                      onClick={() => {
                        sendSearch();
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <Calendar
                  style={{
                    marginBottom: "50px",
                  }}
                />

                <Box>
                  <BoxTitle>Categorias</BoxTitle>
                  <BoxContent>
                    <CategoriaLists>
                      <CategoriaList>
                        {categories.map(category => (
                          <Link to={`/agenda-anteriores/categoria/${category.uri}`}>
                            <CategoriaItem color={category.color}>{category.name}</CategoriaItem>
                          </Link>
                        ))}
                      </CategoriaList>
                    </CategoriaLists>
                  </BoxContent>
                </Box>
              </Aside>
              <ContentBody>
                {events.map(event => (
                  <ListItem>
                    <Link to={`/agenda/detalhes/${event.uri}`}>
                      <ListItemFigure title={event.name} alt={event.name}>
                        <ListItemImage src={event.image_path} title={event.name} alt={event.name} />
                      </ListItemFigure>
                    </Link>
                    <ListItemContent>
                      <Link to={`/agenda/detalhes/${event.uri}`}>
                        <ListItemTitle>{event.name}</ListItemTitle>
                      </Link>

                      <Link to={`/agenda/detalhes/${event.uri}`}>
                        <ListItemDate>
                          {getDateByTimeZoneCba(event.start_date, "'De' dd 'de' MMMM 'de' yyyy")}{" "}
                          {getDateByTimeZoneCba(event.end_date, "'à' dd 'de' MMMM 'de' yyyy")}
                        </ListItemDate>
                      </Link>
                      <Link to={`/agenda/detalhes/${event.uri}`}>
                        <ListItemSummary>{event.summary}</ListItemSummary>
                      </Link>
                      <ListItemLink to={`/agenda/detalhes/${event.uri}`}>CLIQUE PARA SABER MAIS</ListItemLink>

                      <BoxType color={event.type.color}>
                        <div>
                          <Cromt width={190} height={72.14} fillColor="#FFFFFF" />
                          {event.type.name}
                        </div>
                      </BoxType>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    schedule: state.scheduleReducers.schedule,
  };
};
const mapDispatchToProps = {
  getBanner,
  getSchedule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Schedule),
);
