import React from "react";
import { Link } from "react-router-dom";
import { getYear } from "date-fns";
import { useSelector } from "react-redux";

import { Container, Nav, Items, Item, SocialMedia, Copy, Description } from "./styles";

import logo from "./../../assets/logo/footer.png";
import GooglePlay from "./../../assets/icons/google-play-badge.png";
import AppStore from "./../../assets/icons/apple-play-badge.png";
import Developer from "./../../assets/logo/zukt";
import Marker from "./../../assets/icons/marker";
import Clock from "./../../assets/icons/clock";
import Email from "./../../assets/icons/email";
import Phone from "./../../assets/icons/phone";
import { Facebook, Instagram, Twitter, Youtube } from "./../../assets/icons/socialMedia";
import WhatsButton from "../WhatsButton";

export default function Footer() {
  let { configReducers: config } = useSelector(state => state);
  config = config.config;

  return (
    <>
      <Container id="footer">
        <img className="logo" src={logo} width={724} height={114} style={{ alignSelf: "center" }} alt="Logo CRO-MT" />
        <Nav>
          <Items>
            <Item>
              <Link to="/contato" alt="Endereço do CRO-MT" title="Endereço do CRO-MT">
                <Marker width={35} height={27.43} fillColor="#FFFFFF" />
                <span>{config.address}</span>
              </Link>
            </Item>
            <Item>
              <Link to="/contato" alt="Fale conosco - Nosso horário de funcionamento" title="Fale conosco - Nosso horário de funcionamento">
                <Clock width={28.81} height={28} fillColor="#FFFFFF" />
                <span>{config.business_hours}</span>
              </Link>
            </Item>
            <Item>
              <a href={`mailto:${config.emails}`} title="Fale conosco pelo e-mail do CRO-MT" alt="Fale conosco pelo e-mail do CRO-MT">
                <Email width={22} height={22.64} fillColor="#FFFFFF" />
                <span>{config.emails}</span>
              </a>
              <a
                href={`tel:${config.phones}`}
                title="Fale conosco pelos principais telefones do CRO-MT"
                alt="Fale conosco pelos principais telefones do CRO-MT">
                <Phone width={20} height={24.53} fillColor="#FFFFFF" />
                <span>{config.phones}</span>
              </a>
            </Item>
          </Items>
        </Nav>

        <SocialMedia>
          <div>
            <a href="https://www.facebook.com/cro.mt" target="_blank" rel="noopener noreferrer" title="Siga-nos no Facebook">
              <Facebook width={34.57} height={34.57} fillColor="#FFFFFF" />
            </a>
            <a href="https://www.instagram.com/conselhoregionalodontologiamt/" target="_blank" rel="noopener noreferrer" title="Siga-nos no Instagram">
              <Instagram width={35.73} height={35.73} fillColor="#FFFFFF" />
            </a>
            {/*<a
            href="https://www.facebook.com/cro.mt"
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter do CRO-MT">
            <Twitter width={42.37} height={34.42} fillColor="#FFFFFF" />
          </a>*/}
            <a href="https://www.youtube.com/results?search_query=CRO-MT" target="_blank" rel="noopener noreferrer" title="Inscreva em nosso canal do Youtube">
              <Youtube width={48.85} height={34.57} fillColor="#FFFFFF" />
            </a>
          </div>
          <div>
            <a href="https://apps.apple.com/br/app/cro-mt/id1527562597" target="_blank">
              <img src={AppStore} alt="Baixe o Aplicativo na App Store" width="175px" height="55px" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.cromt" target="_blank">
              <img src={GooglePlay} alt="Baixe o Aplicativo na Google Play" width="175px" height="55px" />
            </a>
          </div>
        </SocialMedia>

        <Copy>
          <Description>
            {process.env.REACT_APP_COPYRIGHT} &copy; {getYear(new Date())}. Todos os direitos reservados.
          </Description>
          {/* <a
            href="https://onsidetecnologia.com.br/?utm_source=cromt&utm_medium=onsites&utm_campaign=footer"
            target="_blank"
            rel="opener referrer"
            title="Site do Desenvolvedor">
            <Developer width={60.87} height={34.45} fillColor="#FFFFFF" />
          </a> */}
        </Copy>
      </Container>

      {/* {config.whatsapp ? <WhatsButton phone={config?.whatsapp} message={config?.whatsapp_text || ""} /> : null} */}
    </>
  );
}
