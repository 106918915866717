import React, { useState, useEffect } from "react";

import { Link, useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getCourseDetails } from "./../../../store/courses.details.reducers";
import { getDate, maskCpfCnpj, getDateByTimeZoneCba } from "./../../../services/functions";
import { differenceInDays, parseISO } from "date-fns";
import api from "./../../../services/api";
import { isAuthenticated, getToken, login, logout, getId } from "./../../../services/auth";

import Header from "./../../../components/Header";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";

import Forgot from "./../../../assets/icons/forgot";
import User from "./../../../assets/icons/user";
import Check from "./../../../assets/icons/check";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
  ButtonLink,
  ButtonLinkA,
  ContentSubTitle,
  BoxContentLink,
  AsideButton,
} from "./styles";

const frontload = async props => {
  await props.getCourseDetails(`${process.env.REACT_APP_API}/courses/uri/${props.match.params.uri || ""}`, {}, props.history);
  return;
};

function Details() {
  let { coursesDetailsReducers: course } = useSelector(state => state);
  course = course.courseDetails;

  const { uri } = useParams();
  const history = useHistory();

  const [filter, setFilter] = useState("");
  const [fontSize, setFontSize] = useState(0);
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [logged, setLogged] = useState(false);
  const [course_id, setCourse_id] = useState();
  const [loading, setLoading] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [subscribe_click, setSubscribe_click] = useState(false);

  function handleAnchor(to) {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`#${to}`)) {
        const offset = global.window.document.querySelector(`#${to}`).offsetTop;
        window.scrollTo({ top: offset, left: 0, behavior: "smooth" });

        global.window.document.querySelector(`#cpf`).value = "";
        global.window.document.querySelector(`#password`).value = "";
      }
    }
  }

  const handleSubmitLogin = async event => {
    event.preventDefault();

    try {
      const response = await api.post("/site_users/session", { cpf, password });

      if (response.data) {
        login(response.data.id, response.data.email, response.data.name);
        setLogged(true);
        checkSubscribe();
      } else {
        alert("Erro desconhecido, tente novamente");
      }
    } catch (error) {
      console.log(error);
      alert("Usuário ou Senha Inválidos");
      if (error.response.status) {
        console.log(error.response.status);
      }
    }
  };

  const handleSubscribe = async event => {
    if (!isAuthenticated()) {
      handleAnchor("box-login");
      alert("Para se inscrever no curso, você precisa fazer login e/ou se cadastrar em nosso site!");

      return false;
    }

    setLoading(true);

    const site_user_id = getId();
    try {
      const { data } = await api.post(`/courses/${course.id}/subscribe`, { site_user_id });
      alert("Sua inscrição foi confirmada com sucesso!\nEncaminhamos um e-mail com as informações da sua inscrição");

      setSubscribe(true);

      setLoading(false);
    } catch (error) {
      if (error.response.status && error.response.status === 409) {
        alert("Sua inscrição já foi realizada para esse curso.");

        setSubscribe(true);
      } else {
        console.log(error);
        alert("Desculpe, ocorreu um erro ao realizar a sua inscrição!\nTente novamente ou fale conosco pelos canais de atendimento.");
      }

      setLoading(false);
    }
  };

  const checkSubscribe = async () => {
    try {
      const site_user_id = getId();
      const { data } = await api.get(`/courses/${course.id}/subscribe/${site_user_id}`, {});
      console.log(data);
      setSubscribe(true);
    } catch (error) {
      setSubscribe(false);
    }
  };

  useEffect(() => {
    if (!uri) {
      history.push("/cursos");
    }

    if (isAuthenticated()) {
      setLogged(true);
    } else {
      setLogged(false);
    }
    checkSubscribe();
  }, []);

  return (
    <>
      <Helmet>
        <title>{course.name}</title>
        <meta name="description" content={course.summary || course.local} />
        <meta property="og:title" content={course.name} />
        <meta name="title" content={course.name} />
        <meta property="og:description" content={course.summary || course.local} />
        <meta property="og:image" content={course.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`${course.name}`} />
            <ContentMain>
              <Aside>
                {logged ? (
                  <></>
                ) : (
                  <>
                    <Box id="box-login">
                      <BoxTitle>Login Cursos</BoxTitle>
                      <BoxContent autoComplete="off" autocomplete="off" onSubmit={handleSubmitLogin} id="formLogin">
                        <FilterLabel>CPF</FilterLabel>
                        <FilterInput type="text" name="cpf" id="cpf" value={cpf} required onChange={event => setCpf(maskCpfCnpj(event.target))} />
                        <FilterLabel style={{ marginTop: "20px" }}>SENHA</FilterLabel>
                        <FilterInput type="password" id="password" value={password} required onChange={event => setPassword(event.target.value)} />
                        <FilterButton id="loginButtom">ENTRAR</FilterButton>
                      </BoxContent>
                      <BoxContent style={{ paddingTop: 0 }}>
                        <BoxContentLink to="/usuario/recupere-sua-conta">
                          <Forgot /> Esqueceu a senha?
                        </BoxContentLink>

                        <BoxContentLink to={`/usuario/criar-uma-conta/${course.id}`}>
                          <User /> Crie sua conta
                        </BoxContentLink>
                      </BoxContent>
                    </Box>
                  </>
                )}

                <AsideButton to="/emitir-certificados">
                  <Check width={35.96} height={40.88} fillColor="#FFFFFF" />
                  Autenticar Certificado
                </AsideButton>

                {logged ? (
                  <>
                    <AsideButton to="/usuario/meus-dados">
                      <User fillColor="#FFFFFF" /> MEUS DADOS
                    </AsideButton>
                  </>
                ) : (
                  <></>
                )}

                <Box>
                  <BoxTitle>Pesquisar cursos</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por nome, local, descrição</FilterLabel>
                    <FilterInput type="text" value={filter} onChange={event => setFilter(event.target.value)} />
                    <FilterButton
                      onClick={() => {
                        history.push(`/cursos/buscar/${filter}`);
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>
              </Aside>

              <ContentBody>
                {course.image_path ? (
                  <ContentFigure title={course.name} alt={course.name}>
                    <ContentImage src={course.image_path} title={course.name} alt={course.name} />
                  </ContentFigure>
                ) : (
                  <></>
                )}

                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                  </ContentZoom>
                  <ContentTitle fontSize={fontSize}>{course.name}</ContentTitle>

                  <ContentLine
                    fontSize={fontSize}
                    style={{
                      marginTop: "28px",
                      borderBottom: 0,
                    }}>
                    <label>Palestrante(s):</label>
                  </ContentLine>

                  <ContentSubTitle
                    fontSize={fontSize}
                    dangerouslySetInnerHTML={{
                      __html: course.instructors || "",
                    }}
                  />

                  <ContentSummary
                    fontSize={fontSize}
                    dangerouslySetInnerHTML={{
                      __html: course.curriculum || "",
                    }}
                  />

                  <ContentLine fontSize={fontSize}>
                    {course.id > 50 ? (
                      <>
                        <label>Data:</label> {getDateByTimeZoneCba(course.start_date, "dd 'de' MMMM 'de' yyyy")}
                      </>
                    ) : (
                      <>
                        <label>Data:</label> {getDate(course.start_date, "dd 'de' MMMM 'de' yyyy")}
                      </>
                    )}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    {course.id > 50 ? (
                      <>
                        <label>Horário:</label> {getDateByTimeZoneCba(course.start_date, "HH:mm'hrs'")}
                        {course.end_date ? ` - ${getDateByTimeZoneCba(course.end_date, "HH:mm'hrs'")}` : ``}
                      </>
                    ) : (
                      <>
                        <label>Horário:</label> {getDate(course.start_date, "HH:mm'hrs'")}
                        {course.end_date ? ` - ${getDate(course.end_date, "HH:mm'hrs'")}` : ``}
                      </>
                    )}
                  </ContentLine>

                  {course.total_hours ? (
                    <ContentLine fontSize={fontSize}>
                      <label>Carga horária:</label> {course.total_hours}hrs
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {course.capacity ? (
                    <ContentLine fontSize={fontSize}>
                      <label>Capacidade:</label> {course.capacity}
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {course.is_virtual ? (
                    <ContentLine fontSize={fontSize}>
                      <label>Tipo de curso:</label> Digital/Online/EAD
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {course.address ? (
                    <ContentLine fontSize={fontSize}>
                      <label>Endereço:</label> {course.address}
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {!course.is_virtual ? (
                    <ContentLine fontSize={fontSize}>
                      <label>Local:</label> {course.local}
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {!subscribe && differenceInDays(parseISO(course.end_date), new Date()) >= 0 ? (
                    <ContentLine fontSize={fontSize} style={{ borderBottom: 0 }}>
                      <ButtonLink
                        onClick={() => {
                          handleSubscribe();
                          setSubscribe_click(true);
                        }}>
                        {loading ? `REALIZANDO INSCRIÇÃO...` : `QUERO ME INSCREVER`}
                      </ButtonLink>
                    </ContentLine>
                  ) : (
                    <></>
                  )}

                  {subscribe && course.is_virtual && differenceInDays(parseISO(course.end_date), new Date()) >= 0 ? (
                    <ContentLine fontSize={fontSize} style={{ borderBottom: 0 }}>
                      <ButtonLinkA href={course.local} target="_blank" rel="opener referrer">
                        Clique aqui para acessar a plataforma: {course.local}
                      </ButtonLinkA>
                    </ContentLine>
                  ) : (
                    <></>
                  )}
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    courseDetails: state.coursesDetailsReducers.courseDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getCourseDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
