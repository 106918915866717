import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";

import { getBanner } from "./../../../store/banner.reducers";
import { getRegional } from "./../../../store/regional.reducers";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

import Speaker from "./../../../assets/icons/speaker";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  AsideButton,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
} from "./styles";

const frontload = async props => {
  await props.getRegional(`${process.env.REACT_APP_API}/regionais/${props.match.params.id}`, {}, props.history);
  return;
};

function Details() {
  let { regionalReducers: regional } = useSelector(state => state);
  regional = regional.regional;

  const { uri } = useParams();
  const history = useHistory();

  const [fontSize, setFontSize] = useState(0);

  const availableDays = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

  // Função para determinar se uma delegacia está online ou offline
  const isOnline = item => {
    const now = moment().tz("America/Cuiaba");
    const currentDay = now.day();
    const currentTime = parseInt(now.hours() + "" + now.minutes());

    const isOpen = item.days.includes(currentDay);

    if (isOpen) {
      return currentTime >= parseInt(item.start_hour.replace(":", "")) && currentTime <= parseInt(item.end_hour.replace(":", ""));
    }

    return false;
  };

  React.useEffect(() => {
    const hideChat = () => {
      if (typeof window !== "undefined") {
        const chatIframe = document.querySelector("#iframe-sz-chat");
        if (chatIframe) {
          chatIframe.style.opacity = "0";
        }
      }
    };

    hideChat();

    // Adiciona listeners para garantir que o iframe seja ocultado após o carregamento completo
    if (typeof window !== "undefined") {
      window.addEventListener("load", hideChat);
      return () => window.removeEventListener("load", hideChat);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Delegacias Online</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Delegacias Online `} />
        <meta name="title" content={`Delegacias Online `} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        {regional && regional.id ? (
          <Container>
            <Content>
              <Title title={`Delegacia online de ${regional.city}`} />
              <ContentMain>
                <Aside>
                  <MainServices />
                </Aside>

                <ContentBody>
                  <ContentBox>
                    <ContentTitle fontSize={fontSize}>{regional.city}</ContentTitle>

                    <ContentLine fontSize={fontSize}>
                      <label>Delegado(a):</label> {regional.delegate}
                    </ContentLine>

                    <ContentLine fontSize={fontSize}>
                      <label>Telefone:</label> {regional.phones}
                    </ContentLine>

                    <ContentLine fontSize={fontSize}>
                      <label>E-mail:</label> {regional.emails}
                    </ContentLine>

                    <ContentLine fontSize={fontSize}>
                      <label>Horário de funcionamento:</label> {regional.start_hour.replace(":", "h")} - {regional.end_hour.replace(":", "h")}
                    </ContentLine>

                    <ContentLine fontSize={fontSize}>
                      <label>Dias:</label> {regional.days.map(day => availableDays[day]).join(", ")}
                    </ContentLine>

                    {isOnline(regional) ? (
                      <AsideButton
                        onClick={() => {
                          if (typeof window !== "undefined") {
                            const chatIframe = document.querySelector("#iframe-sz-chat");
                            if (chatIframe) {
                              chatIframe.style.opacity = "1";
                            }
                          }
                        }}>
                        ABRIR CHAT
                      </AsideButton>
                    ) : null}
                  </ContentBox>
                </ContentBody>
              </ContentMain>
            </Content>
          </Container>
        ) : (
          <Container>
            <Content>
              <Title title={`Delegacia não encontrada`} />
            </Content>
          </Container>
        )}
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    regional: state.regionalReducers.regional,
  };
};
const mapDispatchToProps = {
  getBanner,
  getRegional,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
