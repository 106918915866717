import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getAdsDetails } from "./../../../store/ads.details.reducers";
import api from "./../../../services/api";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

import Speaker from "./../../../assets/icons/speaker";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  AsideButton,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
} from "./styles";

const frontload = async props => {
  await props.getAdsDetails(`${process.env.REACT_APP_API}/ads/uri/${props.match.params.uri || ""}`, {}, props.history);
  return;
};

function Details() {
  let { adsDetailsReducers: ads } = useSelector(state => state);
  ads = ads.adsDetails;

  const { uri } = useParams();
  const history = useHistory();

  const [fontSize, setFontSize] = useState(0);

  const [types, setTypes] = useState([]);

  useEffect(() => {
    async function loadTypes() {
      const { data } = await api.get("/ads/types");
      setTypes(data);
    }

    if (!uri) {
      history.push("/classificados/");
    }

    loadTypes();
  }, []);

  return (
    <>
      <Helmet>
        <title>{ads.title}</title>
        <meta name="description" content={ads.description} />
        <meta property="og:title" content={ads.title} />
        <meta name="title" content={ads.title} />
        <meta property="og:description" content={ads.description} />
        <meta property="og:image" content={ads.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`${ads.title}`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Categorias</BoxTitle>
                  <BoxContent>
                    <BoxLists>
                      <BoxList>
                        {types.map(({ id, name, uri }) => (
                          <Link to={`/classificados/?tipo=${uri}`} key={id}>
                            <BoxItem>{name}</BoxItem>
                          </Link>
                        ))}
                      </BoxList>
                    </BoxLists>
                  </BoxContent>
                </Box>

                <AsideButton to="/cadastrar-anuncio">
                  <Speaker width={35.96} height={40.88} fillColor="#FFFFFF" />
                  CADASTRAR ANÚNCIO
                </AsideButton>

                <MainServices />
              </Aside>

              <ContentBody>
                {ads.image_path ? (
                  <ContentFigure title={ads.title} alt={ads.title}>
                    <ContentImage src={ads.image_path} title={ads.v} alt={ads.title} />
                  </ContentFigure>
                ) : (
                  <></>
                )}

                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                  </ContentZoom>
                  <ContentTitle fontSize={fontSize}>{ads.title}</ContentTitle>
                  <ContentSummary
                    fontSize={fontSize}
                    dangerouslySetInnerHTML={{
                      __html: ads.description || "",
                    }}
                  />

                  <ContentLine fontSize={fontSize}>
                    <label>Contato:</label> {ads.name}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    <label>Telefone:</label> {ads.tel}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    <label>E-mail:</label> {ads.email}
                  </ContentLine>

                  <ContentLine fontSize={fontSize}>
                    <label>Nº CRO-MT:</label> {ads.cro}
                  </ContentLine>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    adsDetails: state.adsDetailsReducers.adsDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getAdsDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
