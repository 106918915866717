import React, { useState } from "react";
import Pagination from "react-js-pagination";
import { useHistory, Link } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getVideos } from "./../../store/videos.reducers";
import { getDate, handlePaginateChange, handleGetPaginate, stripAccents, getDateByTimeZoneCba } from "./../../services/functions";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemLink,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
} from "./styles";

let PATH = "/tv-cro/";

const frontload = async props => {
  await props.getVideos(`${process.env.REACT_APP_API}/videos`, {
    headers: { pagenumber: props.match.params.page || 1, pagelimit: 10, term: stripAccents(props.match.params.filter || "") },
  });
  return;
};

function Videos(props) {
  if (props.match.params.filter) {
    PATH = `/tv-cro/buscar/${props.match.params.filter}/`;
  }

  let { videosReducers: videos } = useSelector(state => state);
  const paginate = videos.videos;
  videos = videos.videos.data;

  const history = useHistory();
  const [filterTerm, setFilterTerm] = useState();

  function sendSearch() {
    if (filterTerm) {
      history.push(`/tv-cro/buscar/${filterTerm}/`);
    } else {
      history.push(`/tv-cro/`);
    }
  }

  return (
    <>
      <Helmet>
        <title>
          TV CRO-MT - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`TV CRO-MT - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`TV CRO-MT - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`TV CRO-MT`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Filtrar Vídeos</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por título, conteúdo...</FilterLabel>
                    <FilterInput type="text" value={filterTerm} onChange={event => setFilterTerm(event.target.value)} />

                    <FilterButton
                      onClick={() => {
                        sendSearch();
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>
                <MainServices />
              </Aside>
              <ContentBody>
                {videos.map(video => (
                  <ListItem key={video.id}>
                    {video.image_path ? (
                      <Link to={`/tv-cro/detalhes/${video.uri}`}>
                        <ListItemFigure title={video.title} alt={video.title}>
                          <ListItemImage src={video.image_path} title={video.title} alt={video.title} />
                        </ListItemFigure>
                      </Link>
                    ) : (
                      <></>
                    )}

                    <ListItemContent>
                      <Link to={`/tv-cro/detalhes/${video.uri}`}>
                        <ListItemTitle>{video.title}</ListItemTitle>
                      </Link>

                      <Link to={`/tv-cro/detalhes/${video.uri}`}>
                        <ListItemSubTitle>{getDateByTimeZoneCba(video.date, "'De' dd 'de' MMMM 'de' yyyy")}</ListItemSubTitle>
                      </Link>
                      <ListItemLink to={`/tv-cro/detalhes/${video.uri}`}>VER VÍDEO</ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    videos: state.videosReducers.videos,
  };
};
const mapDispatchToProps = {
  getBanner,
  getVideos,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Videos),
);
