import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "viewerjs-react/dist/index.css";
import RViewerJS from "viewerjs-react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getNewsDetails } from "./../../../store/news.details.reducers";
import { getDate, getDateByTimeZoneCba } from "./../../../services/functions";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import MainServices from "./../../../components/MainServices";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import Share from "./../../../components/Share";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
} from "./styles";

const frontload = async props => {
  await props.getNewsDetails(`${process.env.REACT_APP_API}/news/uri/${props.match.params.uri || ""}`, {}, props.history);
  return;
};

function Details() {
  let { newsDetailsReducers: datails } = useSelector(state => state);
  datails = datails.newsDetails;

  const history = useHistory();

  const [filter, setFilter] = useState("");

  const [fontSize, setFontSize] = useState(0);

  return (
    <>
      <Helmet>
        <title>{datails.title}</title>
        <meta name="description" content={datails.summary} />
        <meta property="og:title" content={datails.title} />
        <meta name="title" content={datails.title} />
        <meta property="og:description" content={datails.summary} />
        <meta property="og:image" content={datails.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title uppercase={false} title={`${datails.title}`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Pesquisar notícia</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por título, conteúdo...</FilterLabel>
                    <FilterInput type="text" value={filter} onChange={event => setFilter(event.target.value)} />
                    <FilterButton
                      onClick={() => {
                        history.push(`/noticias/?filtro=${filter}`);
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                    <Share />
                  </ContentZoom>
                  <ContentLine fontSize={fontSize}>{getDateByTimeZoneCba(datails.date, "dd 'de' MMMM 'de' yyyy")}</ContentLine>
                  <ContentTitle fontSize={fontSize}>{datails.title}</ContentTitle>

                  {/* <div style={{ display: "flex" }}> */}

                  <ContentSummary fontSize={fontSize} className="ck-content">
                    {datails.image_path ? (
                      // <ContentFigure title={datails.title} alt={datails.title}>
                      <RViewerJS>
                        <ContentImage src={datails.image_path} title={datails.title} alt={datails.title} />
                      </RViewerJS>
                    ) : (
                      // </ContentFigure>
                      <></>
                    )}

                    <span
                      dangerouslySetInnerHTML={{
                        __html: datails.content || "",
                      }}
                    />
                  </ContentSummary>
                  {/* </div> */}
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    newsDetails: state.newsDetailsReducers.newsDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getNewsDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
