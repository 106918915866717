import React from "react";
import Pagination from "react-js-pagination";
import { useHistory, Link } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getEvents } from "./../../store/events.reducers";
import { getDate, handlePaginateChange, handleGetPaginate, getDateByTimeZoneCba } from "./../../services/functions";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemSummary,
  ListItemLink,
} from "./styles";

const PATH = "/eventos/";
const frontload = async props => {
  await props.getEvents(`${process.env.REACT_APP_API}/events`, {
    headers: { pagenumber: props.match.params.page || 1, pagelimit: 10 },
  });
  return;
};

function Events() {
  let { eventsReducers: events } = useSelector(state => state);
  const paginate = events.events;
  events = events.events.data;

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          Galeria de fotos e eventos - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Galeria de fotos e eventos - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`Galeria de fotos e eventos - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Eventos`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                {events.map(event => (
                  <ListItem key={event.id}>
                    {event.image_path ? (
                      <Link to={`/eventos/detalhes/${event.uri}`}>
                        <ListItemFigure title={event.title} alt={event.title}>
                          <ListItemImage src={event.image_path} title={event.title} alt={event.title} />
                        </ListItemFigure>
                      </Link>
                    ) : (
                      <></>
                    )}

                    <ListItemContent>
                      <Link to={`/eventos/detalhes/${event.uri}`}>
                        <ListItemTitle>{event.title}</ListItemTitle>
                      </Link>
                      <Link to={`/eventos/detalhes/${event.uri}`}>
                        <ListItemSubTitle>{getDateByTimeZoneCba(event.date, "'De' dd 'de' MMMM 'de' yyyy")}</ListItemSubTitle>
                      </Link>
                      <Link to={`/eventos/detalhes/${event.uri}`}>
                        <ListItemSummary>{event.summary}</ListItemSummary>
                      </Link>

                      <ListItemLink to={`/eventos/detalhes/${event.uri}`}>GALERIA DE FOTOS</ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    events: state.eventsReducers.peventsartners,
  };
};
const mapDispatchToProps = {
  getBanner,
  getEvents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Events),
);
