import React from "react";
import { Link } from "react-router-dom";

import { Container, Item } from "./styles";

export default function Dropdown({ items, showing }) {
  const getSubNav = (id, name, uri, target, title) => {
    if (uri !== "#" && uri !== "") {
      if (uri.substr(0, 4) === "http") {
        return (
          <a alt={title} target={target} href={uri} title={title}>
            {name}
          </a>
        );
      } else {
        return (
          <Link to={uri || `/`} alt={title} target={target} title={title}>
            {name}
          </Link>
        );
      }
    }
  };

  return (
    <Container showing={showing}>
      {items.map(({ id, name, uri, target, title }) => (
        <Item key={id}>{getSubNav(id, name, uri, target, title)}</Item>
      ))}
    </Container>
  );
}
