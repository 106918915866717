import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getTeam } from "./../../store/team.reducers";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import email2 from "./../../assets/icons/email2.svg";
import company from "./../../assets/icons/company.svg";
import phone2 from "./../../assets/icons/phone2.svg";
import whatsapp from "./../../assets/icons/whatsapp.svg";

import { stripAccents } from "./../../services/functions";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemLine,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
} from "./styles";

let PATH = "/equipe-tecnica/";
const frontload = async props => {
  await props.getTeam(`${process.env.REACT_APP_API}/teams`, {
    headers: { pagenumber: props.match.params.page || 1, pagelimit: 10, term: stripAccents(props.match.params.filter || "") },
  });
  return;
};

function Team(props) {
  if (props.match.params.filter) {
    PATH = `/equipe-tecnica/buscar/${props.match.params.filter}/`;
  }
  let { teamReducers: team } = useSelector(state => state);
  team = team.team.data;

  const [filterTerm, setFilterTerm] = useState();
  const history = useHistory();

  function sendSearch() {
    if (filterTerm) {
      history.push(`/equipe-tecnica/buscar/${filterTerm}/`);
    } else {
      history.push(`/equipe-tecnica/`);
    }
  }
  return (
    <>
      <Helmet>
        <title>Conheça nossa equipe técnica - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Conheça nossa equipe técnica - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Conheça nossa equipe técnica - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Equipe técnica`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Filtro</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por nome, função, departamento...</FilterLabel>
                    <FilterInput type="text" value={filterTerm} onChange={event => setFilterTerm(event.target.value)} />

                    <FilterButton
                      onClick={() => {
                        sendSearch();
                      }}>
                      Filtrar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>
              <ContentBody>
                {team.map(item => (
                  <ListItem key={item.id}>
                    {item.image_path ? (
                      <ListItemFigure title={item.name} alt={item.name}>
                        <ListItemImage src={item.image_path} title={item.name} alt={item.name} />
                      </ListItemFigure>
                    ) : (
                      <></>
                    )}

                    <ListItemContent>
                      <ListItemTitle>{item.name}</ListItemTitle>
                      <ListItemSubTitle>{item.occupation}</ListItemSubTitle>
                      <ListItemLine>
                        <img src={company} alt="Departamento" />
                        {item.department}
                      </ListItemLine>
                      <ListItemLine>
                        <img src={email2} alt="E-mail" />
                        {item.email}
                      </ListItemLine>
                      {item.phones ? (
                        <ListItemLine>
                          <img src={phone2} alt="Telefone" />
                          {item.phones}
                        </ListItemLine>
                      ) : (
                        <></>
                      )}

                      {item.whatsapp ? (
                        <ListItemLine style={{ marginTop: 10 }}>
                          <img style={{ width: 20, height: 20 }} src={whatsapp} alt="Whatsapp" />
                          <a target="_blank" href={`https://wa.me/55${item.whatsapp.replace(/\D/g, "")}`}>
                            {item.whatsapp}
                          </a>
                        </ListItemLine>
                      ) : (
                        <></>
                      )}
                    </ListItemContent>
                  </ListItem>
                ))}
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    team: state.teamReducers.team,
  };
};
const mapDispatchToProps = {
  getBanner,
  getTeam,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Team),
);
