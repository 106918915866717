import React from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getDownloads } from "./../../store/downloads.reducers";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import DocFile from "./../../assets/icons/doc-file";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemFigureText,
  ListItemContent,
  ListItemTitle,
} from "./styles";

let PATH = "/downloads/";

const frontload = async props => {
  await props.getDownloads(`${process.env.REACT_APP_API}/downloads/${props.match.params.uri}`, {
    //headers: { pagenumber: props.match.params.page || 1, pagelimit: 10 },
  });
  return;
};

function Downloads(props) {
  PATH = `/downloads/${props.match.params.uri}`;

  let { downloadsReducers: downloads } = useSelector(state => state);
  downloads = downloads.downloads;

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          {`Downloads de ${downloads.name}`} - {process.env.REACT_APP_TITLE}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Downloads de ${downloads.name} - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Downloads de ${downloads.name} - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />
      <Main id="main">
        <Container>
          <Content>
            <Title title={downloads.name} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                {downloads.files.map(item => (
                  <ListItem key={item.id}>
                    <ListItemContent>
                      <a href={item.file_path} target="_blank" rel="noopener noreferrer">
                        <ListItemTitle>{item.name}</ListItemTitle>
                      </a>
                    </ListItemContent>

                    <ListItemFigure title={item.name} alt={item.name} href={item.file_path} target="_blank">
                      <DocFile width={43.93} height={53.28} fillColor="#FFFFFF" />
                      <ListItemFigureText>BAIXAR</ListItemFigureText>
                    </ListItemFigure>
                  </ListItem>
                ))}
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    downloads: state.downloadsReducers.downloads,
  };
};
const mapDispatchToProps = {
  getBanner,
  getDownloads,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Downloads),
);
