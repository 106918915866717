import React, { useState, memo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";

import { getConfig } from "./store/config.reducers";
import { getNav } from "./store/nav.reducers";

import NotFound from "./components/404";

import Home from "./pages/Home";

import Agendamento from "./pages/Agendamento";
import JustifyVote from "./pages/JustifyVote";

import Schedule from "./pages/Schedule";
import ScheduleDetails from "./pages/Schedule/Details";

import Partners from "./pages/Partners";
import PartnersDetails from "./pages/Partners/Details";
import PartnersCreate from "./pages/Partners/Create";

import Events from "./pages/Events";
import EventsDetails from "./pages/Events/Details";

import Representatives from "./pages/Representatives";

import Regional from "./pages/Regional";
import RegionalOnline from "./pages/RegionalOnline";
import RegionalOnlineDetails from "./pages/RegionalOnline/Details";
import Team from "./pages/Team";

import News from "./pages/News";
import NewsDetails from "./pages/News/Details";

import Covid from "./pages/Covid";

import Ads from "./pages/Ads";
import AdsDetails from "./pages/Ads/Details";
import AdsCreate from "./pages/Ads/Create";

import Files from "./pages/Files";

import Downloads from "./pages/Downloads";

import Plenary from "./pages/Plenary";

import Videos from "./pages/Videos";
import VideosDetails from "./pages/Videos/Details";

import Contact from "./pages/Contact";

import Registration from "./pages/Registration";

import Magazines from "./pages/Magazines";

import Presidents from "./pages/Presidents";

import Courses from "./pages/Courses";
import CoursesDetails from "./pages/Courses/Details";
import CoursesAuth from "./pages/Courses/Auth";
import CoursesAuthPrint from "./pages/Courses/Print";

import Pages from "./pages/Pages";

import AccountCreate from "./pages/Account/Create";
import AccountData from "./pages/Account/Data";
import AccountForgot from "./pages/Account/Forgot";
import AccountRenew from "./pages/Account/Renew";

import light from "./styles/themes/light";
import Fisc from "./pages/Fisc";
import ScheduleLasted from "./pages/ScheduleLasted";
import WhatsButton from "./components/WhatsButton";

const frontload = async props => {
  await props.getConfig(`${process.env.REACT_APP_API}/site_config`);
  await props.getNav(`${process.env.REACT_APP_API}/navigations`);
  return;
};

const redirect = (url, domain, props) => {
  if (typeof global.window !== "undefined") {
    if (domain === true) {
      document.location.href = url;
    } else {
      props.history.push(url);
    }
  }
};

const Routes = () => {
  const [theme, setTheme] = useState(light);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/" component={props => <Home {...props} />} />
        <Route exact path="/paginas/:uri" component={props => <Pages {...props} />} />

        <Route exact path="/fisc" component={props => <Fisc {...props} />} />
        <Route exact path="/agenda-anteriores" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/:page" component={props => <Schedule {...props} />} />

        <Route exact path="/agenda/data/:date/:page" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/data/:date/" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/buscar/:filter/:page" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/buscar/:filter/" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/categoria/:category/:page" component={props => <Schedule {...props} />} />
        <Route exact path="/agenda/categoria/:category/" component={props => <Schedule {...props} />} />

        <Route exact path="/agenda/detalhes/:uri" component={props => <ScheduleDetails {...props} />} />

        <Route exact path="/agenda-anteriores/:page" component={props => <ScheduleLasted {...props} />} />

        <Route exact path="/agenda-anteriores/data/:date/:page" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda-anteriores/data/:date/" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda-anteriores/buscar/:filter/:page" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda-anteriores/buscar/:filter/" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda-anteriores/categoria/:category/:page" component={props => <ScheduleLasted {...props} />} />
        <Route exact path="/agenda-anteriores/categoria/:category/" component={props => <ScheduleLasted {...props} />} />

        <Route exact path="/cursos" component={props => <Courses {...props} />} />
        <Route exact path="/cursos/atualizar/:id" component={props => <AccountRenew {...props} />} />
        <Route exact path="/cursos/:page" component={props => <Courses {...props} />} />

        <Route exact path="/cursos/buscar/:filter/:page" component={props => <Courses {...props} />} />
        <Route exact path="/cursos/buscar/:filter/" component={props => <Courses {...props} />} />

        <Route exact path="/cursos/detalhes/:uri" component={props => <CoursesDetails {...props} />} />

        <Route exact path="/emitir-certificados/" component={props => <CoursesAuth {...props} />} />
        <Route exact path="/emitir-certificados/:key/:cpf" component={props => <CoursesAuthPrint {...props} />} />

        <Route exact path="/parceiros" component={props => <Partners {...props} />} />
        <Route exact path="/parceiros/:id/:uri" component={props => <PartnersDetails {...props} />} />
        <Route exact path="/parceiros/:page" component={props => <Partners {...props} />} />

        <Route exact path="/parceiros/buscar/:filter?/segmento/:segment" component={props => <Partners {...props} />} />
        <Route exact path="/parceiros/buscar/:filter?/segmento/:segment/:page" component={props => <Partners {...props} />} />

        <Route exact path="/cadastrar-parceiros/" component={props => <PartnersCreate {...props} />} />

        <Route exact path="/eventos" component={props => <Events {...props} />} />
        <Route exact path="/eventos/:page" component={props => <Events {...props} />} />
        <Route exact path="/eventos/detalhes/:uri" component={props => <EventsDetails {...props} />} />
        <Route exact path="/eventos/fotos/:uri" component={props => <EventsDetails {...props} />} />

        <Route exact path="/representantes" component={props => <Representatives {...props} />} />
        <Route exact path="/representantes/:page" component={props => <Representatives {...props} />} />
        <Route exact path="/representantes/buscar/:filter" component={props => <Representatives {...props} />} />
        <Route exact path="/representantes/buscar/:filter/:page" component={props => <Representatives {...props} />} />

        <Route exact path="/equipe-tecnica" component={props => <Team {...props} />} />
        <Route exact path="/equipe-tecnica/buscar/:filter" component={props => <Team {...props} />} />

        <Route exact path="/delegacias" component={props => <Regional {...props} />} />
        <Route exact path="/delegacias-online" component={props => <RegionalOnline {...props} />} />
        <Route exact path="/delegacias-online/:id" component={props => <RegionalOnlineDetails {...props} />} />
        <Route exact path="/agendamento_online" component={props => <Agendamento {...props} />} />

        <Route exact path="/justificativa" component={props => <JustifyVote {...props} />} />

        <Route exact path="/covid" component={props => <Covid {...props} />} />
        <Route exact path="/covid/:page" component={props => <Covid {...props} />} />
        <Route exact path="/covid/buscar/:filter" component={props => <Covid {...props} />} />
        <Route exact path="/covid/buscar/:filter/:page" component={props => <Covid {...props} />} />

        <Route exact path="/noticias" component={props => <News {...props} />} />
        <Route exact path="/noticias/:page" component={props => <News {...props} />} />
        <Route exact path="/noticias/detalhes/:uri" component={props => <NewsDetails {...props} />} />
        <Route exact path="/noticias/buscar/:filter/" component={props => <News {...props} />} />
        <Route exact path="/noticias/buscar/:filter/:page" component={props => <News {...props} />} />

        <Route exact path="/classificados/" component={props => <Ads {...props} />} />
        <Route exact path="/classificados/:page" component={props => <Ads {...props} />} />
        <Route exact path="/classificados/detalhes/:uri" component={props => <AdsDetails {...props} />} />

        <Route exact path="/classificados/categoria/:category" component={props => <Ads {...props} />} />
        <Route exact path="/classificados/categoria/:category/:page" component={props => <Ads {...props} />} />

        <Route exact path="/anuncie-no-cromt/" component={props => <AdsCreate {...props} />} />
        <Route exact path="/cadastrar-anuncio/" component={props => <AdsCreate {...props} />} />
        <Route exact path="/documentos-necessarios/" component={props => <Files {...props} />} />
        <Route exact path="/documentos-necessarios/:page" component={props => <Files {...props} />} />
        <Route exact path="/plenario" component={props => <Plenary {...props} />} />
        <Route exact path="/plenario/:page" component={props => <Plenary {...props} />} />

        <Route exact path="/tv-cro" component={props => <Videos {...props} />} />
        <Route exact path="/tv-cro/:page" component={props => <Videos {...props} />} />
        <Route exact path="/tv-cro/detalhes/:uri" component={props => <VideosDetails {...props} />} />

        <Route exact path="/tv-cro/buscar/:filter" component={props => <Videos {...props} />} />
        <Route exact path="/tv-cro/buscar/:filter/:page" component={props => <Videos {...props} />} />

        <Route exact path="/contato" component={props => <Contact {...props} />} />
        <Route exact path="/atualize-seu-cadastro" component={props => <Registration {...props} />} />
        <Route exact path="/revista/" component={props => <Magazines {...props} />} />
        <Route exact path="/revista/:page" component={props => <Magazines {...props} />} />
        <Route exact path="/revistas/" component={props => <Magazines {...props} />} />
        <Route exact path="/revistas/:page" component={props => <Magazines {...props} />} />
        <Route exact path="/galeria-de-presidentes/" component={props => <Presidents {...props} />} />
        <Route exact path="/downloads/:uri" component={props => <Downloads {...props} />} />

        <Route exact path="/usuario/criar-uma-conta/:course_id" component={props => <AccountCreate {...props} />} />
        <Route exact path="/usuario/criar-uma-conta" component={props => <AccountCreate {...props} />} />
        <Route exact path="/usuario/meus-dados" component={props => <AccountData {...props} />} />
        <Route exact path="/usuario/recupere-sua-conta" component={props => <AccountForgot {...props} />} />

        {/* legado */}
        <Route exact path="/index/revista/:page" component={props => <Magazines {...props} />} />
        <Route exact path="/legislacao/download/:page" component={props => <Home {...props} />} />
        <Route exact path="/parceiros/pagina-:page" component={props => <Partners {...props} />} />
        <Route exact path="/institucional/pagina-12" component={props => <Representatives {...props} />} />
        <Route exact path="/institucional/pagina-14" component={props => <Team {...props} />} />
        <Route exact path="/institucional" component={props => <Home {...props} />} />
        <Route exact path="/cro-mt" component={props => <Home {...props} />} />

        <Route exact path="/aluguel/:uri" render={props => redirect("/classificados/?tipo=aluguel", false, props)} />
        <Route exact path="/venda-e-compra/:uri" render={props => redirect("/classificados/?tipo=venda-e-compra", false, props)} />
        <Route exact path="/empregos/:uri" render={props => redirect("/classificados/?tipo=empregos", false, props)} />
        <Route exact path="/aluguel/" render={props => redirect("/classificados/?tipo=aluguel", false, props)} />
        <Route exact path="/venda-e-compra/" render={props => redirect("/classificados/?tipo=venda-e-compra", false, props)} />
        <Route exact path="/empregos/" render={props => redirect("/classificados/?tipo=empregos", false, props)} />

        <Route exact path="/classificados/pagina-87" render={props => redirect("/paginas/duvidas-cadastro-de-anuncios", false, props)} />
        <Route exact path="/classificados/pagina-88" render={props => redirect("/paginas/regulamento-cadastro-de-anuncios", false, props)} />

        <Route exact path="/aluguel2/" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />

        <Route exact path="/editais" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/eleicao" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/portarias-e-pareceres" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/prestacao-de-contas" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/comissoes" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/editais/:uri" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/licitacao" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/cursos" render={props => redirect("http://cursos.cromt.org.br/", true, props)} />
        <Route exact path="/editais/publicacao/:page" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />

        <Route exact path="/institucional/pagina-9" render={props => redirect("/paginas/carta-do-presidente", false, props)} />
        <Route exact path="/institucional/pagina-16" render={props => redirect("/paginas/missao-visao-e-valores", false, props)} />
        <Route exact path="/institucional/pagina-17" render={props => redirect("/paginas/politica-da-qualidade-do-cromt", false, props)} />

        <Route exact path="/acesso-a-informacao" render={props => redirect("http://transparencia.cromt.org.br/", true, props)} />
        <Route exact path="/profissionais" render={props => redirect("http://website.cfo.org.br/profissionais-cadastrados/", true, props)} />
        <Route exact path="/anuidades-e-taxas" render={props => redirect("http://service.cfo.org.br/", true, props)} />
        <Route exact path="/artigos" render={props => redirect("/noticias", false, props)} />
        <Route exact path="/dados-estatisticos" render={props => redirect("/paginas/dados-estatisticos", false, props)} />
        <Route exact path="/sede" render={props => redirect("/contato", false, props)} />

        <Route exact path="/ouvidoria" render={props => redirect("https://participar.com.br/cromt/users/sign_in", true, props)} />

        {/* links fixos pois são usados no qrcode, caso mude algum link interno, deve ser vir aqui e fazer o novo apontamento */}
        <Route exact path="/qrcode/index" render={props => redirect("https://cromt.org.br/?source=qrcode", true, props)} />
        <Route exact path="/qrcode/delegacias" render={props => redirect("https://cromt.org.br/delegacias?source=qrcode", true, props)} />
        <Route exact path="/qrcode/classificados" render={props => redirect("http://cromt.org.br/classificados", true, props)} />
        <Route exact path="/qrcode/boletos" render={props => redirect("http://service.cfo.org.br/", true, props)} />
        <Route exact path="/qrcode/profissionais-cadastrados" render={props => redirect("http://website.cfo.org.br/profissionais-cadastrados/", true, props)} />
        <Route
          exact
          path="/qrcode/documentos-necessarios"
          render={props => redirect("https://cromt.org.br/downloads/documentos-necessarios?source=qrcode", true, props)}
        />
        <Route exact path="/qrcode/atualize-seu-cadastro" render={props => redirect("https://cromt.org.br/atualize-seu-cadastro?source=qrcode", true, props)} />
        <Route exact path="/qrcode/emissao-de-certidoes" render={props => redirect("https://cromt.org.br/emissao-de-certidoes?source=qrcode", true, props)} />
        <Route exact path="/qrcode/noticias" render={props => redirect("https://cromt.org.br/noticias?source=qrcode", true, props)} />
        <Route exact path="/qrcode/revista" render={props => redirect("https://cromt.org.br/revista?source=qrcode", true, props)} />
        <Route exact path="/qrcode/agenda" render={props => redirect("https://cromt.org.br/agenda?source=qrcode", true, props)} />
        <Route exact path="/qrcode/codigo-de-etica" render={props => redirect("https://cromt.org.br/downloads/codigo-de-etica?source=qrcode", true, props)} />
        <Route
          exact
          path="/qrcode/modelos-de-documentos"
          render={props => redirect("https://cromt.org.br/downloads/modelos-de-documentos?source=qrcode", true, props)}
        />
        <Route
          exact
          path="/qrcode/manuais-e-cartilhas"
          render={props => redirect("https://cromt.org.br/downloads/manuais-e-cartilhas?source=qrcode", true, props)}
        />
        <Route exact path="/qrcode/cartilhas" render={props => redirect("https://cromt.org.br/downloads/cartilhas?source=qrcode", true, props)} />

        <Route component={() => <NotFound />} />
        <Route exact path="/:uri" component={props => <Pages {...props} />} />
      </Switch>
    </ThemeProvider>
  );
};

// export default About
const mapStateToProps = state => {
  return {
    config: state.configReducers.config,
    nav: state.navReducers.nav,
  };
};
const mapDispatchToProps = {
  getConfig,
  getNav,
};

export default memo(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false,
    })(Routes),
  ),
);
